/*
 * @Descripttion:
 * @version:
 * @Author: xuyang
 * @Date: 2022-09-24 09:28:21
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-24 10:53:17
 */
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import promiseMiddleware from 'redux-promise';
// import { storeData, tempStoreData } from '@/store/reducers';

const reducers = combineReducers({});

const persistConfig = {
  key: 'root',
  storage,
  // 黑名单 不会持久缓存
  blacklist: ['tempStoreData']
};

// 在开发环境时 开启redux tools
const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const myPersistReducer = persistReducer(persistConfig, reducers);
const store: any = createStore(myPersistReducer, composeEnhancers(applyMiddleware(promiseMiddleware)));
const persistor = persistStore(store);

export { store, persistor };
