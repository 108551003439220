/*
 * @Author: xuyang
 * @Date: 2022-10-14 23:57:49
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-16 21:31:46
 */
export const trendUrls = {
  shop: '/shop', // 统计数据
  order: '/order/sl', // 订单统计
  sales: 'order/je', // 销售统计
  visiter: 'visiter' // 访客分析
};
