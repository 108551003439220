/*
 * @Author: xuyang
 * @Date: 2022-10-16 18:56:41
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-16 23:32:57
 * 趋势分析
 */
import React, { FC, useState, useEffect } from 'react';
import { DatePicker, Spin, Table } from 'antd';
import { Line } from '@ant-design/charts';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { config } from './config';
import './index.less';
import axios from '@/utils/request/request';
import { trendUrls } from './const';
import { INormalList } from '../Monitor/type';

const Trend: FC = () => {
  // 路由
  const routerParam = useParams();
  // 类型
  const [type, setType] = useState<string>('shop');
  const [timeValue, setTimeValue] = useState<any>([moment().subtract(14, 'days'), moment()]);
  const [chartData, setChartData] = useState<Array<INormalList>>([]);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // 日期变化
  const timeChange = (value: any) => {
    setTimeValue(value);
  };
  // 获取key值
  const getKeys = (list: any) => Object.keys(list).sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
  // 获取参数
  const getParams = () => ({
    beg: timeValue[0].format('YYYY-MM-DD'),
    end: timeValue[1].format('YYYY-MM-DD')
  });
  // 店铺数据
  const loadShopData = () => {
    setLoading(true);
    axios
      .get(trendUrls.shop, { params: getParams() })
      .then((data: any) => {
        setLoading(false);
        const as: Array<INormalList> = [];
        const ts: Array<any> = [];
        const keys = getKeys(data.zcdp);
        const keys1 = getKeys(data.zcdp1);
        keys.forEach((item: string, index: number) => {
          const k = item.split('-')[2];
          as.push({
            type: k,
            value: data?.zcdp[item],
            category: '注册店铺数',
            title: item
          });
          as.push({
            type: k,
            value: data?.zcdp1[keys1[index]],
            category: '注册店铺数环比',
            title: item
          });
          as.push({
            type: k,
            value: data?.hddp[item],
            category: '活跃店铺数',
            title: item
          });
          as.push({
            type: k,
            value: data?.hddp1[keys1[index]],
            category: '活跃店铺数环比',
            title: item
          });
          ts.push({
            time: item,
            active: data?.hddp[item],
            register: data?.zcdp[item],
            lost: '-'
          });
        });
        setChartData(as);
        setTableData(ts);
      })
      .catch(function () {
        setLoading(false);
      });
  };
  // 加载订单数量
  const loadOrderData = () => {
    setLoading(true);
    axios
      .get(trendUrls.order, { params: getParams() })
      .then((data: any) => {
        setLoading(false);
        const as: Array<INormalList> = [];
        const ts: Array<any> = [];
        const keys = getKeys(data?.ddzs);
        const keys1 = getKeys(data?.ddzs1);
        keys.forEach((item: string, index: number) => {
          const k = item.split('-')[2];
          as.push({
            type: k,
            value: data?.ddzs[item],
            category: '订单总数本周期',
            title: item
          });
          as.push({
            type: k,
            value: data?.ddzs1[keys1[index]],
            category: '订单总数环比',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.wzfdd[item],
            category: '未支付订单本周期',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.wzfdd1[keys1[index]],
            category: '未支付订单环比',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.yzfdd[item],
            category: '已支付订单本周期',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.yzfdd1[keys1[index]],
            category: '已支付订单环比',
            title: keys[index]
          });
          ts.push({
            time: item,
            paid: data?.yzfdd[item],
            unpaid: data?.wzfdd[item],
            total: data?.ddzs[item],
            paidCompare: data?.yzfdd1[keys1[index]],
            unpaidCompare: data?.wzfdd1[keys1[index]],
            totalCompare: data?.ddzs1[keys1[index]]
          });
        });
        setChartData(as);
        setTableData(ts);
        return data;
      })
      .catch(function () {
        setLoading(false);
      });
  };
  // 加载销售额数据
  const loadSaleData = () => {
    setLoading(true);
    axios
      .get(trendUrls.sales, { params: getParams() })
      .then((data: any) => {
        setLoading(false);
        const as: Array<INormalList> = [];
        const ts: Array<any> = [];
        const keys = getKeys(data?.zje);
        const keys1 = getKeys(data?.zje1);
        keys.forEach((item: string, index: number) => {
          const k = item.split('-')[2];
          as.push({
            type: k,
            value: data?.zje[item],
            category: '总金额本周期',
            title: item
          });
          as.push({
            type: k,
            value: data?.zje1[keys1[index]],
            category: '总金额本环比',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.wzfje[item],
            category: '未支付金额本周期',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.wzfje1[keys1[index]],
            category: '未支付金额环比',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.yzfje[item],
            category: '已支付金额本周期',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.yzfje1[keys1[index]],
            category: '已支付金额环比',
            title: keys[index]
          });
          ts.push({
            time: item,
            paid: data?.yzfje[item],
            unpaid: data?.wzfje[item],
            total: data?.zje[item],
            paidCompare: data?.yzfje1[keys1[index]],
            unpaidCompare: data?.wzfje1[keys1[index]],
            totalCompare: data?.zje1[keys1[index]]
          });
        });
        setChartData(as);
        setTableData(ts);
        return data;
      })
      .catch(function () {
        setLoading(false);
      });
  };
  // 加载访客数据
  const loadVisiterData = () => {
    setLoading(true);
    axios
      .get(trendUrls.visiter, { params: getParams() })
      .then((data: any) => {
        setLoading(false);
        const as: Array<INormalList> = [];
        const ts: Array<any> = [];
        const keys = getKeys(data.fk);
        const keys1 = getKeys(data.fk1);
        keys.forEach((item: string, index: number) => {
          const k = item.split('-')[2];
          as.push({
            type: k,
            value: data?.fk[item],
            category: '访客量',
            title: item
          });
          // as.push({
          //   type: k,
          //   value: data?.fk1[keys1[index]],
          //   category: '访客量同比',
          //   title: item
          // });
          as.push({
            type: k,
            value: data?.jgwc[item],
            category: '加入购物车',
            title: item
          });
          // as.push({
          //   type: k,
          //   value: data?.jgwc1[keys1[index]],
          //   category: '加入购物车同比',
          //   title: item
          // });
          as.push({
            type: k,
            value: data?.jrjz[item],
            category: '进入结账',
            title: item
          });
          // as.push({
          //   type: k,
          //   value: data?.jrjz1[keys1[index]],
          //   category: '进入结账同比',
          //   title: item
          // });
          as.push({
            type: k,
            value: data?.wcjz[item],
            category: '完成结账',
            title: item
          });
          // as.push({
          //   type: k,
          //   value: data?.jrjz1[keys1[index]],
          //   category: '完成结账同比',
          //   title: item
          // });
          ts.push({
            time: item,
            visiter: data?.fk[item],
            car: data?.jgwc[item],
            enterSettlement: data?.jrjz[item],
            complete: data?.wcjz[item],
            visiterCompare: data?.fk1[keys1[index]],
            carCompare: data?.jgwc1[keys1[index]],
            enterSettlementCompare: data?.jrjz1[keys1[index]],
            completeCompare: data?.wcjz1[keys1[index]]
          });
        });
        setChartData(as);
        setTableData(ts);
      })
      .catch(function () {
        setLoading(false);
      });
  };
  // 加载数据
  const loadData = () => {
    if (type === 'shop') {
      loadShopData();
    } else if (type === 'order') {
      loadOrderData();
    } else if (type === 'sales') {
      loadSaleData();
    } else if (type === 'visiter') {
      loadVisiterData();
    }
    return null;
  };
  useEffect(() => {
    // console.log(routerParam);
    const t = routerParam.type;
    if (!!t) {
      console.log(t);
      setType(t);
    } else {
      setType('shop');
    }
  }, []);
  //  日期变化
  useEffect(() => {
    loadData();
  }, [timeValue, type]);

  const chartConfig = {
    data: chartData,
    xField: 'type',
    yField: 'value',
    Legend: {
      position: 'bottom' as const
    },
    seriesField: 'category',
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v: any) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
      }
    }
  };
  return (
    <Spin spinning={loading}>
      <div className="trend-main">
        <div className="trend-search">
          <DatePicker.RangePicker onChange={timeChange} placeholder={['开始日期', '结束日期']} value={timeValue} />
        </div>
        <div className="trend-chart">
          <p className="trend-title">趋势分析</p>
          <div className="trend-chart-main">
            <Line {...chartConfig}></Line>
          </div>
        </div>
        <div className="trend-table">
          <p className="trend-title">数据报告</p>
          <div className="trend-table-content">
            <Table rowKey="time" columns={config[type].columns} dataSource={tableData}></Table>
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default Trend;
