/*
 * @Descripttion:
 * @version:
 * @Author: xuyang
 * @Date: 2022-09-22 23:35:06
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-28 22:35:25
 */
import React, { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import LayoutPage from './views/LayoutPage';
import Login from './views/Login';

const App: FC = () => (
  <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="*" element={<LayoutPage />}></Route>
      </Routes>
    </BrowserRouter>
  </div>
);

export default App;
