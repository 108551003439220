/*
 * @Descripttion:
 * @version:
 * @Author: xuyang
 * @Date: 2022-09-22 23:35:06
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-24 11:01:19
 */
export enum StatusCode {
  // 接口正常返回码
  STATUS_200 = 200,
  // 后端服务不可用
  STATUS_503 = 503
}

export interface IPromiseData {
  code: number;
  message: string;
  content: any;
}
