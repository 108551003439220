/*
 * @Author: xuyang
 * @Date: 2022-09-28 23:30:28
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-28 23:41:32
 * @FilePath: \react_antd\src\components\LeftMenu\config.ts
 * @Description: 配置项
 */
import React from 'react';
import { IMenuList } from './type';
import { HomeOutlined } from '@ant-design/icons';

export const menuList: Array<IMenuList> = [
  {
    key: 'monitor',
    label: '首页',
    icon: '',
    url: 'monitor'
  },
  {
    key: 'trend',
    label: '店铺趋势',
    url: 'trend',
    children: [
      {
        key: 'trendShop',
        label: '店铺趋势',
        url: 'shop',
        purl: 'trend'
      },
      {
        key: 'trendOrder',
        label: '订单趋势分析',
        url: 'order',
        purl: 'trend'
      },
      {
        key: 'trendSales',
        label: '销售趋势分析',
        url: 'sales',
        purl: 'trend'
      },
      {
        key: 'trendVisitor',
        label: '访客数据分析',
        url: 'visiter',
        purl: 'trend'
      }
    ]
  },
  {
    key: 'order',
    label: '订单数据',
    icon: '',
    children: [
      {
        key: 'allOrders',
        label: '全部订单'
      },
      {
        key: 'order-giveOff',
        label: '弃单订单'
      },
      {
        key: 'order-afterSales',
        label: '售后订单'
      },
      {
        key: 'order-dispute',
        label: '争议订单'
      }
    ]
  },
  {
    key: 'sale',
    label: '销售数据',
    icon: ''
  },
  {
    key: 'visitorData',
    label: '访客数据',
    icon: ''
  },
  {
    key: 'shopRank',
    label: '店铺排行',
    icon: '',
    url: 'shopRank'
  },
  {
    key: 'productRank',
    label: '商品销售排行',
    icon: '',
    url: 'productRank'
  }
];
