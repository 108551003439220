/*
 * @Author: xuyang
 * @Date: 2022-10-16 22:10:21
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-16 22:35:06
 */
export const shopUrls = {
  rank: '/shop/sort' // 排行榜
};
// 店铺状态
export const shopStatus = {
  delete: '已删除',
  shutdown: '已关闭',
  normal: '正常'
};
