/*
 * @Author: xuyang
 * @Date: 2022-10-13 22:11:15
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-15 18:15:10
 */

import React, { useState, useEffect, FC } from 'react';
import { Row, Col } from 'antd';
import '../index.less';
import axios from '@/utils/request/request';
import { monitorUrls } from '../const';
import { IHeaderData } from '../type';
import userImg from '@/asset/img/users.png';
const TopInfo: FC = () => {
  const [activeShops, setActiveShops] = useState<IHeaderData>({
    zhydps: {},
    zzcdps: {},
    jrddl: {},
    jrxse: {},
    jrfks: {}
  });
  // 获取统计数据
  const loadSummary = () => {
    axios.get(monitorUrls.summary).then((data: any) => {
      setActiveShops(data);
      return data;
    });
  };

  useEffect(() => {
    loadSummary();
  }, []);
  // 头部配置
  const headerColConfig = {
    xs: {
      span: 24
    },
    lg: {
      span: 4
    },
    sm: {
      span: 12
    }
  };
  return (
    <div>
      {/* 头部信息 */}
      <Row gutter={[16, 16]}>
        {/* 周活跃店铺数 */}
        <Col {...headerColConfig}>
          <div className="header-item header-c1">
            <p className="title">周活跃店铺数</p>
            <p className="mainTxt">{activeShops.zhydps?.zhydps}</p>
            <div className="info">
              <div className="info-list">
                <p>累计店铺数: {activeShops.zhydps?.ljdps}</p>
                <p>环比增长：{(activeShops.zhydps?.hbzz ? activeShops.zhydps?.hbzz * 100 : 0) + '%'}</p>
                <p>上周活跃店铺数:{activeShops.zhydps?.szhydps}</p>
              </div>
              <div className="icon">
                <img src={userImg} alt="" />
              </div>
            </div>
          </div>
        </Col>
        {/* 周注册店铺数 */}
        <Col {...headerColConfig}>
          <div className="header-item header-c2">
            <p className="title">周注册店铺数</p>
            <p className="mainTxt">{activeShops.zzcdps?.zzcdps}</p>
            <div className="info">
              <div className="info-list">
                <p>累计注册店铺: {activeShops.zzcdps?.ljzcdps}</p>
                <p>环比增长：{(activeShops.zzcdps?.hbzz ? activeShops.zzcdps?.hbzz * 100 : 0) + '%'}</p>
                <p>上周注册店铺数: {activeShops.zzcdps?.szzcdps}</p>
              </div>
              <div className="icon">
                <img src={userImg} alt="" />
              </div>
            </div>
          </div>
        </Col>
        {/* 今日订单量 */}
        <Col {...headerColConfig}>
          <div className="header-item header-c3">
            <p className="title">今日订单量</p>
            <p className="mainTxt">{activeShops.jrddl?.jrddl}</p>
            <div className="info">
              <div className="info-list">
                <p>周累计订单: {activeShops.jrddl?.bzljdd}</p>
                <p>环比增长：{(activeShops.jrddl?.hbzz ? activeShops.jrddl?.hbzz * 100 : 0) + '%'}</p>
                <p>上周累计订单: {activeShops.jrddl?.szljdd}</p>
              </div>
              <div className="icon">
                <img src={userImg} alt="" />
              </div>
            </div>
          </div>
        </Col>
        {/* 今日销售额 */}
        <Col {...headerColConfig}>
          <div className="header-item header-c4">
            <p className="title">今日销售额</p>
            <p className="mainTxt">{activeShops.jrxse?.jrxse}</p>
            <div className="info">
              <div className="info-list">
                <p>周累计销售额: {activeShops.jrxse?.bzljxse}</p>
                <p>环比增长：{(activeShops.jrxse?.hbzz ? activeShops.jrxse?.hbzz * 100 : 0) + '%'}</p>
                <p>上周销售额: {activeShops.jrxse?.szljxse}</p>
              </div>
              <div className="icon">
                <img src={userImg} alt="" />
              </div>
            </div>
          </div>
        </Col>
        {/* 今日访客数 */}
        <Col {...headerColConfig}>
          <div className="header-item header-c5">
            <p className="title">今日访客</p>
            <p className="mainTxt">{activeShops.jrfks?.jrfks}</p>
            <div className="info">
              <div className="info-list">
                <p>周累计访客数: {activeShops.jrfks?.zljfks}</p>
                <p>环比增长：{(activeShops.jrfks?.hzzz ? activeShops.jrfks?.hzzz * 100 : 0) + '%'}</p>
                <p>上周访客数:{activeShops.jrfks?.szljfks}</p>
              </div>
              <div className="icon">
                <img src={userImg} alt="" />
              </div>
            </div>
          </div>
        </Col>
        {/* 访客数行为 */}
        {/* <Col {...headerColConfig}>
          <div className="header-item">
            <p className="title">访客数</p>
            <p className="mainTxt">{activeShops.jrfks?.jrfks}</p>
            <div className="info">
              <div className="info-list">
                <p>累计流失店铺: {activeShops.jrfks?.jgwc}</p>
                <p>环比增长：{activeShops.jrfks?.jrjz}</p>
                <p>同比增长:{activeShops.jrfks?.wczf}</p>
              </div>
              <div className="icon"></div>
            </div>
          </div>
        </Col> */}
      </Row>
    </div>
  );
};
export default TopInfo;
