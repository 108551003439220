/*
 * @Author: xuyang
 * @Date: 2022-10-12 23:52:30
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-13 22:17:14
 * 数据监控
 */
import React, { FC } from 'react';
import './index.less';
import TopInfo from './components/TopInfo';
import CenterInfo from './components/CenterInfo';
import BottomInfo from './components/BottomInfo';

const Monitor: FC = () => {
  return (
    <div className="monitor">
      {/* 头部信息 */}
      <TopInfo />
      {/* 中间信息 */}
      <CenterInfo />
      <BottomInfo />
    </div>
  );
};
export default Monitor;
