import React, { FC, useState, useEffect } from 'react';
import { Button, Dropdown, Avatar, Menu, message } from 'antd';
import Icon, { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { IHeaderProps } from './types';
import './index.less';
interface IUserInfo {
  userName?: string;
  avatar?: string;
}
const Header: FC<IHeaderProps> = ({ onSetCollapsed }: IHeaderProps) => {
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    userName: '',
    avatar: ''
  });
  const [collapsed, setCollapsed] = useState<boolean>(false);
  // 初始加载
  useEffect(() => {
    setUserInfo({
      userName: 'XuXu',
      avatar: ''
    });
  }, []);
  // 菜单事件
  const menuChange = ({ key }: { key: string }): void => {
    if (key === 'modify') {
      message.success('修改密码');
    } else {
      message.success('退出登录');
    }
  };
  // 用户下拉
  const userMenu = (
    <Menu
      onSelect={menuChange}
      items={[
        { label: '修改密码', key: 'modify' },
        { label: '退出登录', key: 'logout' }
      ]}
    ></Menu>
  );
  //菜单栏改变
  const collapsedClick = () => {
    setCollapsed(!collapsed);
    onSetCollapsed(!collapsed);
  };
  return (
    <div className="header">
      <div className="menu-control">
        <Button type="link" onClick={collapsedClick}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
      <div className="header-control">
        <Button type="link" className="clear" icon={<i className="ico-clear"></i>}>
          清除缓存
        </Button>
        <Button type="link" className="help" icon={<i className="ico-help"></i>}>
          清除缓存
        </Button>
        <Button type="link" className="shop" icon={<i className="ico-help"></i>}>
          进入店铺
        </Button>
        <div className="user-center">
          <Dropdown overlay={userMenu}>
            <div>
              <Avatar className="avatar" src={userInfo.avatar} />
              <span>{userInfo.userName}</span>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
