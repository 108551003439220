/*
 * @Author: xuyang
 * @Date: 2022-09-28 22:30:08
 * @LastEditors: xuyang 897103652@qq.com
 * @LastEditTime: 2022-09-28 23:52:07
 * @FilePath: \react_antd\src\components\LeftMenu\index.tsx
 * @Description: 左侧菜单内容
 */
import React, { FC } from 'react';
import { Button, Menu } from 'antd';
import { menuList } from './config';
import { useNavigate } from 'react-router-dom';
import { IMenuProps } from './type';
import logo from '@/asset/img/icon-03.png';

import './index.less';
const LeftMenu: FC<IMenuProps> = () => {
  const navigate = useNavigate();
  // 菜单栏
  const menuChange = ({ item, key, keyPath }: { item: any; key: string; keyPath: string[] }) => {
    const purl = item.props.purl;
    const url = purl ? `${purl}/${item.props.url}` : `${item.props.url}`;
    navigate(url);
  };
  return (
    <div className="left-main">
      <div className="left-box">
        {/* logo */}
        <div className="logo">
          <img src={logo} />
        </div>
        <Menu mode="inline" onSelect={menuChange} theme="light" items={menuList} />
      </div>
      {/* <div className="menu-btns">
        <Button className="btn btn-des" type="link">
          店铺装修
        </Button>
        <Button className="btn btn-set" type="link">
          店铺配置
        </Button>
      </div> */}
    </div>
  );
};

export default LeftMenu;
