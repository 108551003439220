/*
 * @Author: xuyang
 * @Date: 2022-09-28 22:30:37
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-28 23:56:16
 * @FilePath: \react_antd\src\views\LayoutPage\index.tsx
 * @Description: 整体布局页面
 */
import React, { useState } from 'react';
import { Layout } from 'antd';
import Header from '@/components/HeaderComponent';
import './index.less';
import LeftMenu from '@/components/LeftMenu';
import { Routes, Route } from 'react-router-dom';
// import AllOrders from '../Orders/AllOrders';
// const AllOrders = React.lazy(() => import(/* webpackChunkName:'AllOrders' */ '@/views/Orders/AllOrders'));
import AllOrders from '../Orders/AllOrders';
import Monitor from '../Monitor';
import Trend from '../Trend';
import ShopRank from '../ShopRank';
import ProductRank from '../ProductRank';

const { Sider, Content } = Layout;

function LayoutPage(props: any) {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  // 菜单栏展开
  const onMenuCollapsed = (flag: boolean) => {
    console.log(flag);
    setCollapsed(flag);
  };
  return (
    <Layout className="app-main">
      {/* 底部 */}
      <Layout>
        {/* 左侧菜单 */}
        <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
          <LeftMenu collapsed={collapsed}></LeftMenu>
        </Sider>
        {/* 内容 */}
        <Content className="content-main">
          {/* 头部 */}
          <Header onSetCollapsed={onMenuCollapsed}></Header>
          <Routes>
            <Route path="/monitor" element={<Monitor />}></Route>
            <Route path="/allOrders" element={<AllOrders />}></Route>
            <Route path="/shopRank" element={<ShopRank />}></Route>
            <Route path="/productRank" element={<ProductRank />}></Route>
            <Route path="/trend/:type" element={<Trend />}></Route>
            {/* 重定向 */}
            <Route path="*" element={<Monitor />}></Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
}

export default LayoutPage;
