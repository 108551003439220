/*
 * @Author: xuyang
 * @Date: 2022-10-16 20:05:18
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-16 21:40:15
 */
export const config = {
  shop: {
    columns: [
      {
        key: 'time',
        title: '时间',
        dataIndex: 'time'
      },
      {
        key: 'active',
        title: '活跃店铺',
        dataIndex: 'active'
      },
      {
        key: 'register',
        title: '注册店铺数',
        dataIndex: 'register'
      },
      {
        key: 'lost',
        title: '流失店铺数',
        dataIndex: 'lost'
      },
      {
        key: 'action',
        title: '操作',
        render: () => {
          return '查看';
        }
      }
    ]
  },
  order: {
    columns: [
      {
        key: 'time',
        title: '时间',
        dataIndex: 'time'
      },
      {
        key: 'paid',
        title: '已支付订单',
        dataIndex: 'paid'
      },
      {
        key: 'unpaid',
        title: '未支付订单',
        dataIndex: 'unpaid'
      },
      {
        key: 'total',
        title: '订单总数',
        dataIndex: 'total'
      },
      {
        key: 'paidCompare',
        title: '已支付订单(同比)',
        dataIndex: 'paidCompare'
      },
      {
        key: 'unpaidCompare',
        title: '未支付订单(同比)',
        dataIndex: 'unpaidCompare'
      },
      {
        key: 'totalCompare',
        title: '订单总数(同比)',
        dataIndex: 'totalCompare'
      }
    ]
  },
  sales: {
    columns: [
      {
        key: 'time',
        title: '时间',
        dataIndex: 'time'
      },
      {
        key: 'paid',
        title: '已支付金额',
        dataIndex: 'paid'
      },
      {
        key: 'unpaid',
        title: '未支付金额',
        dataIndex: 'unpaid'
      },
      {
        key: 'total',
        title: '总金额',
        dataIndex: 'total'
      },
      {
        key: 'paidCompare',
        title: '已支付金额(同比)',
        dataIndex: 'paidCompare'
      },
      {
        key: 'unpaidCompare',
        title: '未支付金额(同比)',
        dataIndex: 'unpaidCompare'
      },
      {
        key: 'totalCompare',
        title: '总金额(同比)',
        dataIndex: 'totalCompare'
      }
    ]
  },
  visiter: {
    columns: [
      {
        key: 'time',
        title: '时间',
        dataIndex: 'time'
      },
      {
        key: 'visiter',
        title: '访客',
        dataIndex: 'visiter'
      },
      {
        key: 'car',
        title: '加入购物车',
        dataIndex: 'car'
      },
      {
        key: 'enterSettlement',
        title: '进入结账',
        dataIndex: 'enterSettlement'
      },
      {
        key: 'complete',
        title: '完成结账',
        dataIndex: 'complete'
      },
      {
        key: 'visiterCompare',
        title: '访客(同比)',
        dataIndex: 'visiterCompare'
      },
      {
        key: 'carCompare',
        title: '加入购物车(同比)',
        dataIndex: 'carCompare'
      },
      {
        key: 'enterSettlement',
        title: '进入结账(同比)',
        dataIndex: 'enterSettlementCompare'
      },
      {
        key: 'completeCompare',
        title: '完成结账(同比)',
        dataIndex: 'completeCompare'
      }
    ]
  }
};
