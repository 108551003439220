/*
 * @Ddescription:
 * @Author: pengzhou
 * @Date: 2022-03-28 09:34:51
 * @LastEditTime: 2022-09-24 10:46:30
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from '@/store';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import reportWebVitals from './reportWebVitals';
// import worker from './mocks/browser';
import './index.less';
import App from './App';
if (process.env.REACT_APP_MOCK === 'mock') {
  // worker.start({
  //   // 对于没有 mock 的接口直接通过，避免异常
  //   onUnhandledRequest: 'bypass'
  // });
}

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
