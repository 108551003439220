/*
 * @Author: xuyang
 * @Date: 2022-10-16 22:00:41
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-16 23:24:06
 * 店铺排行
 */
import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Table, Space, Spin } from 'antd';
import moment from 'moment';
import './index.less';
import axios from '@/utils/request/request';
import { productStatus, productUrls } from './const';
import { ISort } from './type';
const ProductRank = () => {
  const [timeValue, setTimeValue] = useState<any>([moment().subtract(14, 'days'), moment()]);
  const [tableData, setTableData] = useState<Array<any>>([]); // 表格数据
  const [loading, setLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<ISort>({
    columnKey: 'xse',
    field: 'xse',
    order: 'desc'
  });
  const getParams = () => ({
    beg: timeValue[0].format('YYYY-MM-DD'),
    end: timeValue[1].format('YYYY-MM-DD'),
    order: sort.columnKey,
    mode: sort.order === 'ascend' ? 'asc' : 'desc'
  });
  // 加载数据
  const loadShopData = () => {
    setLoading(true);
    axios
      .get(productUrls.rank, { params: getParams() })
      .then((data: any) => {
        setTableData(data);
        setLoading(false);
        return data;
      })
      .catch(function () {
        setLoading(false);
      });
  };
  // 日期选择
  const timeChange = (value: any) => {
    setTimeValue(value);
  };
  // 搜索按钮
  const searchHandle = () => {
    loadShopData();
  };
  useEffect(() => {
    loadShopData();
  }, [sort]);

  // 表格变化
  const tableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSort(sorter);
  };
  const columns = [
    {
      key: 'product_title',
      title: '商品',
      dataIndex: 'product_title'
    },
    {
      key: 'xse',
      title: '销售额',
      dataIndex: 'xse',
      sorter: true
    },
    {
      key: 'zfdd',
      title: '支付订单',
      dataIndex: 'zfdd',
      sorter: true
    },
    {
      key: 'scdd',
      title: '生成订单',
      dataIndex: 'scdd',
      sorter: true
    },
    {
      key: 'xse',
      title: '累计销售额',
      dataIndex: 'xse'
    },
    {
      key: 'jointime',
      title: '上架时间',
      dataIndex: 'jointime'
    },
    {
      key: 'nickname',
      title: '所属店铺',
      dataIndex: 'nickname'
    },
    {
      key: 'status',
      title: '状态',
      dataIndex: 'status',
      render: (text: string) => {
        return productStatus[text];
      }
    },
    {
      key: 'action',
      title: '查看',
      dataIndex: 'action',
      render(text: string, record: any) {
        return (
          <Button type="link" target="_blank" href={record.url}>
            查看
          </Button>
        );
      }
    }
  ];
  return (
    <Spin spinning={loading}>
      <div className="shop-main">
        <div className="shop-search">
          <Space>
            <DatePicker.RangePicker onChange={timeChange} placeholder={['开始日期', '结束日期']} value={timeValue} />
            <Button type="primary" onClick={searchHandle}>
              确定
            </Button>
          </Space>
        </div>
        <div className="shop-table">
          <Table
            rowKey={(record: any) => record.product_id + ''}
            columns={columns}
            dataSource={tableData}
            onChange={tableChange}
          />
        </div>
      </div>
    </Spin>
  );
};

export default ProductRank;
