/*
 * @Author: xuyang
 * @Date: 2022-09-24 11:14:19
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-27 00:01:03
 * @FilePath: \react_element\src\views\Login\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { FC } from 'react';
import './index.less';
import logo from '@/asset/img/icon-03.png';
import { Button, Form, Input, message } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
type Props = ReduxProps;
const Item = Form.Item;

const Login: FC<Props> = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm<any>();
  /**
   * 完成
   * @param values 表单数据
   */
  const onFinish = (values: any) => {
    console.log(form.getFieldValue('username'));
  };
  /**
   * 结束
   * @param errors 报错
   */
  const onFinishFailed = (errors: any) => {
    console.log(errors);
  };
  /**
   * 登录事件
   */
  const loginHandle = () => {
    form.validateFields().then((values) => {
      message.success(values.username + '登录成功！');
      navigate('/allOrders');
    });
  };
  /**
   * 立即注册
   */
  const registerHandle = () => {
    message.success('立即注册');
  };
  return (
    <div className="login-main">
      <div className="login-form">
        <div className="login-content">
          <img src={logo} alt="" className="login-img" />
          <h1>用户登录</h1>
          <p className="tips">独立建站平台，完整的店铺管理</p>
          <Form
            form={form}
            className="main-form"
            name="basic"
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Item name="username" rules={[{ required: true, message: '请输入用户名!' }]}>
              <Input prefix={<MailOutlined className="input-icon" />} placeholder="邮箱" />
            </Item>

            <Item name="password" rules={[{ required: true, message: '请输入密码!' }]}>
              <Input.Password placeholder="密码" prefix={<LockOutlined className="input-icon" />} />
            </Item>
            <Item>
              <Button type="primary" className="btn-login" onClick={loginHandle}>
                立即登录
              </Button>
            </Item>
          </Form>
          <p className="go-register">
            还没有shoppaas?
            <span className="register-now" onClick={registerHandle}>
              立即注册
            </span>
          </p>
          <span className="forget-psw">忘记密码？</span>
        </div>
      </div>
    </div>
  );
};

export default Login;
