/* eslint-disable @typescript-eslint/ban-ts-comment */
/*
 * @Descripttion:
 * @version:
 * @Author: xuyang
 * @Date: 2022-09-22 23:35:06
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-24 11:01:19
 */
import Axios, { AxiosError, AxiosResponse, ResponseType } from 'axios';
import { message } from 'antd';
import { StatusCode } from './const';

export const NO_NETWORK = '网络未连接, 请检查网络';

const axios = Axios.create({});

// 加入自定义前缀 用于反向代理
axios.defaults.baseURL = 'http://174.137.50.167:8088/';
interface IRequest {
  params: object;
  responseType: ResponseType;
}

interface ResponseData<T> {
  code: number;
  data: T;
  message: string;
}

axios.interceptors.response.use(
  (response: AxiosResponse<ResponseData<any>>) => {
    // 如果没有code 直接返回 如返回的是图片流的情况下
    if (response.status === StatusCode.STATUS_503) {
      return Promise.resolve(response);
    }

    // 请求成功
    if (response.status === StatusCode.STATUS_200) {
      return response.data as any;
    }
    message.error(response.data.message);
    return Promise.reject(new Error(response.data.message));
  },
  (error: AxiosError) => {
    message.error(error.message);
    return Promise.reject(error);
  }
);

// post请求
// @ts-ignore
axios.post = (url: string, params?: object): Promise<any> =>
  axios({
    method: 'post',
    url,
    data: params
  });

// get请求
// @ts-ignore
axios.get = (url: string, param?: IRequest): Promise<any> =>
  axios({
    method: 'get',
    url,
    ...param
  });

export default axios;
